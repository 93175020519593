<template>
  <ProjectList :projects="projects" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import ProjectList from '@/components/organisms/ProjectList.vue'

export default defineComponent({
  name: 'SubcontractorProjects',
  components: {
    ProjectList
  },
  setup() {
    const store = useStore()
    const projects = computed(() => {
      return store.state.studio.SubcontractorDetails?.ProjectService.map((projectService: any) => {
        if (projectService.project) {
          return projectService.project
        }
      })
    })
    return { projects }
  }
})
</script>
